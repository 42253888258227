.stone-img-box{
    height: 71px;
    width: 65px;
}
.nav.nav-tabs .nav-link{
    border:1px solid #dadada;
}
.nav.nav-tabs .nav-link:hover{
    border:1px solid #000;
}

.nav.nav-tabs .nav-link.active{
    border:1px solid #000;
}
.stone-img-box .nav-link{
    color: #000;
}
/* 
.video-section{
    height: 400px;
    width: 400px;
} 
*/
.video-section video { width: 100%;
    height: 100%;}
.stone-descrip-div:nth-child(even){
    background-color: #f1f1f1;
}
.detail-thumbnail-list  { max-width: 90px;}

@media(max-width: 991px){
    .detail-thumbnail-list  { max-width: 60px;}
}
@media(max-width: 575px){
    .stone-img-box {
width: 60px;
}
}
@media(max-width: 767px){
    .gemstone-detail-section .no-border{
        border:none !important;
    }
    .detail-thumbnail-list  { max-width: 100%;}
}

.detail-sec-1
{
    font-family: 'Nunito Sans', sans-serif;
    /* background-color: rgba(0,0,0,0.05); */
    /* border-radius: 25px; */
    color: black;
    font-size: 14px;
}
.gemstone-detail{
    /* border: 1px solid #ccc; */
    /* background-color: #eee; */
    padding: 5px;
}
.gemstone-detail-table{
    width: 100%;
}
.gemstone-detail-table tr td
{
    padding: 5px 15px 5px 0px;
    font-weight: 600;   
}

.custom-btn{
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}
.custom-btn:hover{
    color: white;
}